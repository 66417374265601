<template>
  <div>
    <a-modal v-model="isModalVisible" title="Registrar Ticket">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="Lugar">
          <a-input :value="selectedTableDescription" disabled />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :xs="24" :sm="12">
            <a-form-item label="Boleta de Pago" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="Boleta de Pago" v-decorator="[
                'Boleta',
                { rules: [{ required: true, message: 'Ingresa la boleta' },
                { validator: checkBoleta, message: 'La boleta ya fue registrada' }
                ] }
              ]" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-item label="Correlativo de Ticket" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="Correlativo de Ticket" v-decorator="[
                'Correlativo',
                {
                  rules: [{ required: true, message: 'Ingresa correlativo o varios separados por coma' },
                  { pattern: /^\d+(,\d+)*$/, message: 'Ingresa un número o varios separados por coma' },
                  { validator: checkCorrelativo, message: 'El correlativo ya fue registrado' }
                  ]
                }
              ]" 
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Nombres" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Nombres" v-decorator="[
            'Nombres',
            { rules: [{ required: true, message: 'Ingresa nombre' },] }
          ]" />
        </a-form-item>
        <a-form-item label="Apellidos" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Apellidos" v-decorator="[
            'Apellidos',
            { rules: [{ required: true, message: 'Ingresa apellidos' }] }
          ]" />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :xs="24" :sm="12">
        <a-form-item label="Celular" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Whatsapp/Celular" v-decorator="[
            'Celular',
            {
              rules: [{ required: true, message: 'Ingresa whatsapp/celular' },
              { pattern: /^[0-9]{8}$/, message: 'Ingresa un número de 8 dígitos' }
              ]
            }
          ]" />
        </a-form-item>
      </a-col>
      <a-col :xs="24" :sm="12">

        <a-form-item label="Asociado" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-switch v-model:checked="isAfiliado" @change="calcCostoTicket" />
        </a-form-item>
      </a-col>
      </a-row>

        <a-form-item label="Precio total" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <span style="color: red; font-size: 2em;">Q. {{ costoTicket }}</span>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel">Cancelar</a-button>
        <a-button type="primary" @click="handleOk" :loading="loading">OK Crear</a-button>
    </template>
    </a-modal>
    <a-col :xs="23" :sm="23">

    <div class="seating-layout">
      <div class="row general">
        <Seat v-for="num in [300]" :key="num" :seat="getSeat(num)" :handleClick="createTicket" style="width: 100%;"/>
      </div>
      <div class="row general">
        <hr style="width: 100%; border: 1px dotted grey; margin: 0;"/>
      </div>
      <!-- <div class="row">
        <Seat v-for="num in [51, 52,53,54,55,100,56,57,58,59,60]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div> -->
      <!-- Row 1: Tables 41-50 -->
      <!-- <div class="row">
        <Seat v-for="num in [41, 42, 43, 44, 45, 100, 46, 47, 48, 49, 50]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div> -->
      <!-- Row 2: Tables 31-40 -->
      <div class="row">
        <Seat v-for="num in [53,54,55,56,57,58,59,60,61,62,100,63,64,65,66,67,68,69,70,71,72]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <!-- Row 3: Tables 21-30 -->
      <div class="row">
        <Seat v-for="num in [35,36,37,38,39,40,41,42,43, 100,44,45,46,47,48,49,50,51,52]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <!-- Row 4: Tables 10-20 -->
      <div class="row">
        <Seat v-for="num in [17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <!-- Row 5: Tables 1-9 -->
      <div class="row">
        <Seat v-for="num in [1, 2, 3, 4, 5, 6,  7, 8, 9, 10 ,11, 12,13,14, 15, 16]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <!-- <div class="row">
        <Seat v-for="num in [100, 101, 1, 2, 200, 201, 203, 3, 4, 102]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div> -->

      <div class="row">
        <Seat v-for="num in [100, 101, 200, 201, 202, 203, 204, 205, 103, 104]" :key="num" :seat="getSeat(num)" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row general">
        <div style="background-color: grey; width: 100%; padding: 1em; text-align: center; color: white; font-weight: bold; font-size: 1.5em; border-radius: 10px;">
          Escenario
        </div>
      </div>

    </div>
  </a-col>
  <a-col :xs="1" :sm="1">
    <div class="row"  @click="createTicketPlata">
      <span class="vertical-text" :handleClick="createTicket">Plata {{countTicketsPlata}}/{{ countMesasPlata }}</span>
    </div>
    <!-- <div class="row">
        <Seat v-for="num in [400]" :key="num" :seat="getSeat(num)" :handleClick="createTicket" class="vertical-text"/>
      </div> -->
  </a-col>
  </div>
</template>

<script>
import Seat from './Seat.vue';

export default {
  components: {
    Seat,
  },
  props: {
    mesas: {
      type: Array,
      default: () => [],
    },
    callback:
    {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      selectedTableDescription: '',
      selectedTableType: 'Oro',
      selectedTableId: null,
      costoTicket: 0,
      isModalVisible: false,
      mesaHighlight: 0,
      isAfiliado: false,
      currentCorrelativos: [],

      // mesas: [], // Stores the data from API
      centralSeats: [
      ],
      loading: false,
    };
  },
  mounted() {
    const urlParams = this.$route.query 

    let mesa = urlParams.mesa || 0;
    console.log("highlighting mesa", mesa)
    console.log(mesa)
    this.mesaHighlight = mesa;

    if(this.mesaHighligh){
      setTimeout(() => {
      this.mesaHighlight = 0;
      // remove mesa from url
      this.$router.push({ query: {} });
    }, 7000);
    }
    
  },
  computed:{
    tickets(){
      return this.mesas.flatMap(mesa => mesa.tickets);
    },
    countMesasPlata () {
      console.log("Counting Mesas Plata")
      let mesa = this.mesas.filter(mesa => mesa.tipo === 'Plata')
      if(mesa.length > 0){
        return mesa[0].capacidad;
      }else{
        return 0;
      }
    },
    countTicketsPlata () {
      let mesas = this.mesas.filter(mesa => mesa.tipo === 'Plata')
      console.log("Counting tickets Mesa Plata", mesas)
      if(mesas.length > 0){
        return mesas[0].tickets.length;
      }else{
        return 0;
      }
    },
  },
  methods: {
    createTicketPlata(){
      this.selectedTableType = 'Plata';
      const mesa = this.mesas.find((mesa) => mesa.numero == 1 && mesa.tipo == 'Plata');
      this.createTicket(mesa);
    },
    calcCostoTicket(){
      
      let countCorrelativos = this.currentCorrelativos.length;
      
      console.log("Calculating costo", countCorrelativos, this.isAfiliado, this.selectedTableType);

      let costoOro = 200;
      let costoOroAsociados = 150;

      let costoVIP = 300;
      let costoVIPAsociados = 200;

      let costoPlata = 150;
      let costoPlataAsociados = 100;

      let costo = 0;
      if(this.selectedTableType === 'Diamante'){
        costo = this.isAfiliado ? costoVIPAsociados : costoVIP;
      }else if(this.selectedTableType === 'Oro'){
        costo = this.isAfiliado ? costoOroAsociados : costoOro;
      }
      else if(this.selectedTableType === 'Plata'){
        costo = this.isAfiliado ? costoPlataAsociados : costoPlata;
      }
      this.costoTicket = costo * countCorrelativos;
    },
    async checkCorrelativo(rule, value, callback) {
      let correlativos = value.split(',');
      console.log(correlativos);

      try {
        for (const correlativo of correlativos) {
          const responseExists = await this.$axios.get(`/tickets?filters[Correlativo][$eq]=${correlativo}&filters[mesa][tipo][$eq]=${this.selectedTableType}`);
          if (responseExists.data.data.length > 0) {
            let exists = responseExists.data.data[0];
            console.log(exists);
            rule.message = `El correlativo ${correlativo} ya fue registrado para ${exists.Nombres} con la boleta ${exists.Boleta}`;
            console.log(rule.message);
            callback(new Error(rule.message));
            return; // Stop the loop and exit the function
          }
        }
        // If no correlativo was found, call the callback with no error
        this.currentCorrelativos = correlativos;
        this.calcCostoTicket();
        callback();
      } catch (error) {
        console.error('Error fetching ticket data:', error);
        callback(new Error('Error fetching ticket data'));
      }
    },

    async checkBoleta(rule, value, callback) {
      console.log("Checking correlativo", value)
      // console.log(this.tickets)
      // const exists = this.tickets.find(ticket => ticket.Correlativo === value);
      const responseExists = await this.$axios.get('/tickets?filters[Boleta][$eq]=' + value)
      console.log("Response Exists")
      console.log(responseExists)
      if (responseExists.data.data.length >0) {
        console.log("Exists", responseExists.data.data[0])
        let exists = responseExists.data.data[0]
        console.log(exists)
        rule.message = `El Numero de boleta ya fue registrado para ${exists.Nombres} con el correlativo ${exists.Correlativo}`;
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    createTicket(mesa) {
      console.log("Creating ticket for mesa", mesa)
      this.loading = false;
      this.selectedTableId = mesa.documentId;
      this.isModalVisible = true;
      if(mesa.tipo == "Plata"){
        this.selectedTableDescription = `${mesa.tipo} #${mesa.numero}`;
      }else {
        this.selectedTableDescription = `Mesa ${mesa.tipo} #${mesa.numero}`;
      }
      
      // this.selectedTableDescription = `Mesa ${mesa.tipo} #${mesa.numero}`;
      this.selectedTableType = mesa.tipo;
      this.isAfiliado = false;
      this.currentCorrelativos = [];
      this.costoTicket = 0;
      // clear fields on modal open
      this.form.resetFields();
    },
    getSeat(num) {
      if (num >= 200 && num < 300) {
        return {
          id: "--",
          description: "Directivos",
          capacity: "Directivos",
          occupied: "",
          tipo: "Directivos",
          mesa: null
        };
      }
      if (num >= 300) {
        let real_num = num - 299;
        const mesa = this.mesas.find((mesa) => mesa.numero == real_num && mesa.tipo == 'Oro');
        if (mesa) {
          console.log("Mesa Oro:", mesa, num, real_num)
          return {
            id: mesa.numero,
            description: mesa.Descripcion,
            capacity: mesa.capacidad,
            occupied: mesa.tickets.length,
            tipo: mesa.tipo,
            mesa: mesa
          };
        }
      }
      const mesa = this.mesas.find((mesa) => mesa.numero === num && mesa.tipo == 'Diamante');
      if (mesa) {
        return {
          id: mesa.numero,
          description: mesa.Descripcion,
          capacity: mesa.capacidad,
          occupied: mesa.tickets.length,
          tipo: mesa.tipo,
          mesa: mesa
        };
      } else {
        // Return a placeholder if no data is found for the seat number
        return {
          id: num,
          description: `Mesa ${num}`,
          capacity: 0,
          occupied: 0,
          tipo: 'N/A',
          mesa: null
        };
      }
    },
    handleCancel() {
      this.isModalVisible = false;
      this.loading = false; 
    },
    async handleOk(e) {
      e.preventDefault();
      this.loading = true; 
      let current_user = JSON.parse(localStorage.getItem('user'));

      try {
        // Get mesa details from API
        let mesa = await this.$axios.get(`/mesas/${this.selectedTableId}?populate=*`);
        console.log("Getting MESA before save", mesa.data.data);

        let mesa_capacidad = mesa.data.data.capacidad;
        let mesa_tickets = mesa.data.data.tickets.length;
        console.log("Capacidad de Mesa", mesa_capacidad);
        console.log("Tickets en Mesa", mesa_tickets);

        // Calculate the number of tickets being created
        let creating_tickets = this.form.getFieldValue('Correlativo').split(',').length;
        console.log("Creating Tickets", creating_tickets);

        // Check if the mesa has enough capacity
        if (creating_tickets + mesa_tickets > mesa_capacidad) {
          this.$error({
            title: 'Error',
            content: 'La mesa seleccionada no tiene espacio suficiente para la cantidad de tickets requeridos',
          });
          this.loading = false; // Reset loading state
          return;
        }

        // Validate form fields
        this.form.validateFields(async (err, values) => {
          if (!err) {
            let correlativos = values.Correlativo.split(',').map(correlativo => correlativo.trim());
            let allSuccess = true;
            let countCorrelativos = correlativos.length;
            let precioPorTicket = this.costoTicket/countCorrelativos

            for (const correlativo of correlativos) {
              let formData = {
                data: {
                  mesa: this.selectedTableId,
                  Boleta: values.Boleta,
                  Nombres: values.Nombres,
                  Apellidos: values.Apellidos,
                  Correlativo: correlativo,
                  celular_whatsapp: values.Celular,
                  Fecha: new Date(),
                  afiliado: this.isAfiliado,
                  precio: precioPorTicket,
                  notas: `Ticket creado por ${current_user.username} con costo Q. ${precioPorTicket} y asociado: ${this.isAfiliado}, cantidad de tickets ${creating_tickets}`,
                  username: current_user.username,
                }
              };

              try {
                this.$gtag.event('create-ticket', { data: formData , user: current_user});
                let response = await this.$axios.post('/tickets', formData);
                console.log(response);
              } catch (err) {
                console.error('Error posting ticket:', err);
                this.$gtag.event('create-ticket-error', { data: formData });
                this.$error({
                  title: 'Error',
                  content: 'Ha ocurrido un error al registrar el ticket, revise que todos los campos esten ingresados y el correlativo no se repita',
                });
                allSuccess = false;
                break; // Stop the iteration if an error occurs
              }
            }

            if (allSuccess) {
              this.isModalVisible = false;
              this.selectedTableId = null;
              this.$success({
                title: 'Ticket registrado',
                content: 'Todos los tickets han sido registrados exitosamente',
              });
              this.callback();
              this.$gtag.event('create-ticket-success', { data: formData });
            }
          } else {
            console.error('Validation Error:', err);
          }
          this.loading = false; // Reset loading state
        });
      } catch (error) {
        console.error('Error fetching mesa data:', error);
        this.$error({
          title: 'Error',
          content: 'Ha ocurrido un error al obtener los datos de la mesa',
        });
        this.loading = false
      }
    },
    handleCloseModal() {
      this.isModalVisible = false;
      this.selectedTableId = null;
    },
  },
};
</script>

<style scoped>
.row.general {
  width: 80%;
}

.seating-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  margin: 10px;
}

.center-section {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.vertical-text {
  writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 20px;
    height: 500px;
    background-color: gray;
    color: white;
    padding: 5px;
    padding-bottom: 10px;
    border-radius: 10px;
    text-align: center;
}
</style>