<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Counter Widgets -->
		<a-row :gutter="24">
			<a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
				<!-- Widget 1 Card -->
				<WidgetCounter
					:title="stat.title"
					:value="stat.value"
					:prefix="stat.prefix"
					:suffix="stat.suffix"
					:icon="stat.icon"
					:status="stat.status"
				></WidgetCounter>
				<!-- / Widget 1 Card -->
			</a-col>
		</a-row>
		<!-- / Counter Widgets -->

		<!-- Charts -->
		<!-- <a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="10" class="mb-24">
				<CardBarChart></CardBarChart>

			</a-col>
			<a-col :span="24" :lg="14" class="mb-24">
				
				<CardLineChart></CardLineChart>

			</a-col>
		</a-row> -->
		<!-- / Charts -->

		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="24" class="mb-24">
				
				<CardMesas :mesas="mesasData" :callback="createdTicketCallback"></CardMesas>

			</a-col>
		</a-row>

		<!-- Table & Timeline -->
		<a-row :gutter="24" type="flex" align="stretch">
			<!-- Table -->
			<a-col :span="24" :lg="24" class="mb-24 md-24">
				<!-- Projects Table Card -->
				<CardProjectTable
					:data="tableData"
					:columns="tableColumns"
					:callback="createdTicketCallback"
					:mesas="mesasData"
				></CardProjectTable>
				<!-- / Projects Table Card -->
				
			</a-col>
			<!-- / Table -->

			<!-- Timeline -->
			<!-- <a-col :span="24" :lg="8" class="mb-24"> -->

				<!-- Orders History Timeline Card -->
				<!-- <CardOrderHistory></CardOrderHistory> -->
				<!-- / Orders History Timeline Card -->

			<!-- </a-col> -->
			<!-- / Timeline -->
		</a-row>
		<!-- / Table & Timeline -->

		<!-- Cards -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :xl="14" class="mb-24">

				<!-- Information Card 1 -->
				<!-- <CardInfo></CardInfo> -->
				<!-- / Information Card 1 -->

			</a-col>
			<!-- <a-col :span="24" :xl="10" class="mb-24"> -->

				<!-- Information Card 2 -->
				<!-- <CardInfo2></CardInfo2> -->
				<!-- / Information Card 2 -->

			<!-- </a-col> -->
		</a-row>
		<!-- / Cards -->

	</div>
</template>

<script>

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	// Information card 1.
	import CardInfo from '../components/Cards/CardInfo' ;

	// Information card 2.
	import CardInfo2 from '../components/Cards/CardInfo2' ;

	import CardMesas from '../components/Cards/CardMesas' ;

	// Counter Widgets stats
	const stats = [
		{
			title: "Tickets Diamante",
			value: 0,
			prefix: "",
			suffix: "0%",
			icon: ``,
			status: 'normal',
		},
		{
			title: "Tickets Oro",
			value: 0,
			suffix: "0%",
			icon: ``,
			status: 'normal',
		}
		,{
			title: "Tickets Plata",
			value: 0,
			suffix: "0%",
			icon: ``,
			status: 'normal',
		},
		{
			title: "Sillas Requeridas",
			value: 0,
			suffix: "",
			icon: ``,
			status: 'danger',
		}
	] ;

	var mesasData = [];
	var ticketsData = [];

	// "Projects" table list of columns and their properties.
	const tableColumns = [
		{
			title: 'Mesa',
			dataIndex: 'company',
			scopedSlots: { customRender: 'company' },
			width: 300,
		},
		// {
		// 	title: 'Miembros',
		// 	dataIndex: 'members',
		// 	scopedSlots: { customRender: 'members' },
		// },
		{
			title: 'Ctd. Miembros',
			dataIndex: 'budget',
			class: 'font-bold text-muted text-sm',
		},
		{
			title: 'Porcentaje',
			scopedSlots: { customRender: 'completion' },
			dataIndex: 'completion',
		},
		{
			title: 'Crear Ticket',
			scopedSlots: { customRender: 'createticket' },
		},
	];

	// "Projects" table list of rows and their properties.
	const tableData = [];

	export default ({
		components: {
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
			CardInfo,
			CardInfo2,
			CardMesas
		},
		// on load, set the data
		mounted() {
			this.stats = stats ;
			this.tableColumns = tableColumns ;
			this.tableData = tableData ;
			this.mesasData = [];
			this.ticketsData = [];
			this.fetchTicketsdata();
			this.fetchMesasData();

			// fetch data every minute
			setInterval(() => {
				this.createdTicketCallback();
			}, 60000)
			
		},
		methods: {
			createdTicketCallback(){
				this.fetchTicketsdata();
				this.fetchMesasData();
			},
			calculateCompletionPercentage(ticketsLength, capacidad) {
				if (capacidad === 0) return {
					value: 0,
					label: '0%',
					status: 'normal',
				}; // Avoid division by zero
				let percentage = Math.round((ticketsLength / capacidad) * 100);
				// round percentage to 0 decimal places
				return {
					label: `${percentage}%`, 
					value:percentage,
					status: this.determineStatus(percentage),
				}

			},
			determineStatus(percentage) {
				if (percentage === 100) return 'exception';
				if (percentage > 50) return 'active';
				return 'success';
			},
			async formatMesasData() {
				// console.log("formatting mesas data");
				this.tableData = [];
				// this.stats = []
				
				await this.mesasData.forEach(mesa => {
					let mesaType = mesa.tipo;
					// set icons
					let icon;
					if (mesa.tipo === 'Diamante') {
						icon = 'images/logos/diamond.png';
					} else if (mesa.tipo === 'Oro') {
						icon = 'images/logos/gold.png';
					} else if (mesa.tipo === 'Plata') {
						icon = 'images/logos/silver.png';
					}
					this.tableData.push({
						key: mesa.id,
						documentId: mesa.documentId,
						company: {
							name: `Mesa ${mesaType} - ${mesa.numero}`,
							logo: icon,
						},
						members: Array(mesa.tickets.length).fill("images/face-0.jpg"),
						budget: `${mesa.tickets.length}/${mesa.capacidad}`,
						completion: this.calculateCompletionPercentage(mesa.tickets.length, mesa.capacidad),
						espacio_disponible: mesa.capacidad - mesa.tickets.length,
					})
				})
				this.formatMesasStatus();
				// console.log(this.tableData);
			},
			formatMesasStatus(){
				let count_tikets_vip = 0;
				let count_tikets_oro = 0;
				let count_mesas_vip = 0;
				let count_mesas_oro = 0;
				let count_mesas_plata = 0;
				let count_tikets_plata = 0;

				if(this.mesasData){
					this.mesasData.forEach(mesa => {
						console.log("Mesa", mesa)
						if(mesa.tipo === 'Diamante'){
							count_mesas_vip += mesa.capacidad;
							count_tikets_vip += mesa.tickets.length;

						}else if(mesa.tipo === 'Oro'){
							count_mesas_oro += mesa.capacidad;
							count_tikets_oro += mesa.tickets.length;
						}
						else if(mesa.tipo === 'Plata'){
							count_mesas_plata += mesa.capacidad;
							count_tikets_plata += mesa.tickets.length;
						}
				})
				}
				

				console.log("Diamante Tickets", count_tikets_vip);
				console.log("Diamante Mesas", count_mesas_vip);

				console.log("ORO Tickets", count_tikets_oro);
				console.log("ORO Mesas", count_mesas_oro);
				console.log("Plata Tickets", count_tikets_plata);
				console.log("Plata Mesas", count_mesas_plata);

				this.stats[0].value = count_tikets_vip;
				this.stats[0].suffix = `${Math.round((count_tikets_vip/count_mesas_vip)*100)}%`;
				this.stats[1].value = count_tikets_oro;
				this.stats[1].suffix = `${Math.round((count_tikets_oro/count_mesas_oro)*100)}%`;

				this.stats[2].value = count_tikets_plata;
				this.stats[2].suffix = `${Math.round((count_tikets_plata/count_mesas_plata)*100)}%`;

				// round count_tikets_oro to next 100, it shoud show, 100, 200, 300...
				this.stats[3].value = Math.ceil(count_tikets_oro/100)*100;
				this.stats[3].suffix = "";

			},
			fetchMesasData(){
				this.$axios.get('/mesas?populate=*&sort=capacidad:desc&pagination[limit]=10000')
					.then(response => {
						this.mesasData = response.data.data ;
						console.log("Mesas")
						console.log(this.mesasData);
						this.formatMesasData();
					})
					.catch(error => {
						console.log(error) ;
					})
			},
			fetchTicketsdata() {
				// this.$axios.get('/tickets?populate=mesa&pagination[1000]')
				// 	.then(response => {
				// 		this.ticketsData = response.data.data ;
				// 	})
				// 	.catch(error => {
				// 		console.log(error) ;
				// 	})
			}
		},
		data() {
			return {

				// Associating table data with its corresponding property.
				tableData,

				// Associating table columns with its corresponding property.
				tableColumns,

				// Counter Widgets Stats
				stats,

				// Mesa data
				mesasData,

				// Tickets data
				ticketsData,
			}
		},
	})

</script>

<style lang="scss">
</style>