<template>
    <div class="seat" :class="{ 'invisible-seat': isInvisible }"  @click="() => onClickSeat(seat)">
      <a-card
        :class="[seatColorClass, { 'highlight-style': highlight }]"
        :title="isDirectivos ? seat.id : (isOro ? 'Oro' : seat.id)"
        class="seat-card"
        :body-style="{ textAlign: 'center' }"
      >
      <p v-if="!isDirectivos">{{ seat.occupied }}/{{ seat.capacity }}</p>
      <p v-if="isDirectivos">10/10</p>
      </a-card>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      seat: {
        type: Object,
        required: true,
      },
      handleClick: {
        type: Function,
        default: () => {},
      },
      highlight:{
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onClickSeat(seat) {
        if(seat.occupied >= seat.capacity) return;
        this.handleClick(seat.mesa);
      },
    },
    computed: {
      // Determine if the seat is Directivos (seat number > 200)
      isDirectivos() {
        return this.seat.tipo == 'Directivos';
      },
      isOro() {
        return this.seat.tipo == 'Oro';
      },
      // Determine if the seat is in the invisible range (number >= 100 and < 200)
      isInvisible() {
        return this.seat.id >= 100 && this.seat.id < 200;
      },
      // Assign the correct color class based on the conditions
      seatColorClass() {
        if (this.isDirectivos) {
          return 'orange-seat';
        }
        if (this.isOro) {
          return 'oro-seat';
        }
        if (this.seat.occupied === 0) {
          return 'grey-seat';
        }
        if (this.seat.occupied >= this.seat.capacity) {
          return 'orange-seat';
        }
        return 'green-seat';
      },
    },
  };
  </script>
  
  <style scoped>
  .seat {
    margin: 2px;
    display: flex;
    justify-content: center;
  }
  
  .seat-card {
    width: 63px; /* Make tables smaller to fit better */
    transition: width 0.3s ease;
  }
  
  /* Invisible seats will occupy space but not be visible */
  .invisible-seat {
    visibility: hidden;
  }
  
  /* Color coding based on seat conditions */
  .grey-seat {
    background-color: #d3d3d3;
  }
  
  .green-seat {
    background-color: #90ee90;
  }
  
  .orange-seat {
    background-color: #aa000e;
    color: white;
  }
  
  .gold-seat {
    background-color: #cab120;
  }
  .oro-seat {
    width: 500% !important;
    background-color: #f5e7af;
    width: 300px;
  }
  ::v-deep .ant-card .ant-card-body {
  padding: 4px !important;
    }
    ::v-deep .ant-card .ant-card-body p {
        margin-bottom: 1em;
    }
::v-deep .ant-card .ant-card-head .ant-card-head-wrapper {
        min-height: 25px;
}
  
::v-deep .ant-card .ant-card-head {
  font-size: 12px !important;
}
  
::v-deep .ant-card .ant-card-head-title {
    padding: 4px 0;
    text-align: center;
    font-size: 12px !important;
    font-weight: bold;
    overflow: visible;
}

::v-deep .ant-card .ant-card-head {
    /* padding: -20px 0; */
    min-height: 30px !important;
    padding: 0px !important
}
@media (max-width: 1530px) {
      .seat-card {
        width: 50px;
      }
    }

    @media (max-width: 1250px) {
      .seat-card {
        width: 45px;
      }
    }

    @media (max-width: 1140px) {
      .seat-card {
        width: 40px;
      }
    }
  /* Responsive for medium-sized screens */
  @media (max-width: 1080px) {
    .seat-card {
      width: 35px;
    }
  }

  /* Responsive for medium-sized screens */
  @media (max-width: 935px) {
    .seat-card {
      width: 30px;
    }
  }

  @media (max-width: 820px) {
    .seat-card {
      width: 25px;
    }
  }
  
  /* Responsive for small-sized screens */
  @media (max-width: 745px) {
    .seat-card {
      width: 20px;
    }
  }
  .highlight-style {
    border: 2px solid blue; /* Distinctive border color */
    box-shadow: 0 0 10px blue; /* Distinctive shadow color */
  }

  ::v-deep .vertical-text .ant-card-head{
    display: none;;
  }
  </style>
  